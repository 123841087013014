<!-- =========================================================================================
    File Name:
    Description:
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author:
    Author URL:
========================================================================================== -->
<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4">
      <vx-card>
        <div class="vx-card__title mb-6">
          <h2 class="text-center">Select Organization Name</h2>
        </div>
        <vs-divider position="center"></vs-divider>
        <div class="subscription">
          <div>
            <multiselect
              v-model="orgValue"
              deselect-label="Can't remove this value"
              track-by="organization_id"
              label="organization_name"
              placeholder="Select Organization"
              :options="organization"
              :searchable="false"
              :allow-empty="false"
              open-direction="bottom"
              @select="updateValueAction"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <strong>{{ option.organization_name }}</strong>
              </template>
            </multiselect>

            <div v-if="showNoProjectError" class="vx-card__title mt-2">
              <h6 style="color: red" class="text-center">
                {{ showNoProjectError }}
              </h6>
            </div>
          </div>
          <vs-button
            :disabled="submitStatus"
            class="w-full mt-5"
            @click.prevent="submitForm"
            >Submit</vs-button
          >
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { BASEURL } from "@/config/index.js";
import UserService from "@/services/UserService.js";
import { TokenService, ProfileService } from "@/services/storage.service.js";
export default {
  data() {
    return {
      showNoProjectError: "",
      orgValue: [],
      organization: [],
      userDetails: {},
      selectedOrganization: null,
      submitStatus: true,
      user_role: "",
    };
  },
  components: {},
  beforeMount() {
    this.getUserProfileInfo();
  },
  mounted() {},
  methods: {
    submitForm: function () {
      localStorage.setItem("OrganizationID", this.selectedOrganization);
      this.$vs.loading();
      setTimeout(() => {
        UserService.getProfileInfoViaOrg(this.selectedOrganization)
          .then((response) => {
            const { data } = response;
            if (data && data.Status) {
              let profile = data.data;
              let userRole = data.data.user_role;
              if (data.companyData.length > 0) {
                this.showNoProjectError = "";
              } else {
                this.showNoProjectError =
                  "You are not a part of any active project..! Please Contact Administrator!";
              }
              let dashboardUser = data.data.dashgetProfileInfoViaOrgboard_user;
              let contractProjects = data.data.contractProjects;
              this.$vs.loading.close();
              let checkUserType = data.data.checkUserType;
              localStorage.setItem("UserID", data.data.user_id);
              localStorage.setItem("isHoldOrg", data.data.is_hold_org);
              localStorage.setItem("UserType", checkUserType);
              localStorage.setItem("isAdvanced", data.data.advance_isActive);
              localStorage.setItem(
                "isCreditNote",
                data.data.creditNote_isActive
              );
              localStorage.setItem(
                "isShootLocation",
                data.data.isShootLocation
              );
              localStorage.setItem("isTally", data.data.tally_isActive);
              localStorage.setItem("digiSign", data.data.digiSign_isActive);
              localStorage.setItem("dcr", data.data.dcr_isActive);
              if (checkUserType == 1) {
                if (userRole == "AdminManager" || userRole == "Moderator") {
                  userRole = "User";
                }
              }
              if (checkUserType == 1) {
                let userName = data.data.user_name;
                localStorage.setItem("UserRole", userRole);
                localStorage.setItem("UserName", userName);
                setTimeout(() => {
                  // this.$router.push({
                  //   path: "/contractView"
                  // });
                  this.$router.push({
                    path: "/apps/projects",
                  });
                }, 500);
                return false;
              }
              if (userRole == "User") {
                if (dashboardUser.length == 0 && contractProjects.length > 0) {
                  setTimeout(() => {
                    // this.$router.push({
                    //   path: "/contractView"
                    // });
                    this.$router.push({
                      path: "/",
                    });
                  }, 500);
                  //this.logout();
                } else {
                  let userName = data.data.user_name;
                  localStorage.setItem("UserRole", userRole);
                  localStorage.setItem("UserName", userName);
                  setTimeout(() => {
                    this.$nextTick(() => {
                      this.$store.dispatch("setPermissions");
                      this.$forceUpdate();
                    });
                  }, 500);
                  setTimeout(() => {
                    this.$router.push({
                      path: "/apps/projects",
                    });
                  }, 500);
                }
              } else if (
                userRole == "Super Admin" ||
                userRole == "Admin" ||
                userRole == "Admin Manager"
              ) {
                let userName = data.data.user_name;
                localStorage.setItem("UserRole", userRole);
                localStorage.setItem("UserName", userName);
                setTimeout(() => {
                  this.$nextTick(() => {
                    this.$store.dispatch("setPermissions");
                    this.$forceUpdate();
                  });
                }, 500);
                // ProfileService.saveProfile(profile);
                setTimeout(() => {
                  this.$router.push({
                    path: "/",
                  });
                }, 500);
              } else {
                let userName = data.data.user_name;
                localStorage.setItem("UserRole", userRole);
                localStorage.setItem("UserName", userName);
                setTimeout(() => {
                  this.$nextTick(() => {
                    this.$store.dispatch("setPermissions");
                    this.$forceUpdate();
                  });
                }, 500);
                // ProfileService.saveProfile(profile);
                setTimeout(() => {
                  this.$router.push({
                    path: "/apps/projects",
                  });
                }, 500);
              }
            }
          })
          .catch((error) => {
            this.$vs.notify({
              title: "error",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning",
            });
          });
      }, 1000);
    },
    getUserProfileInfo: function () {
      UserService.getProfileInfo()
        .then((response) => {
          const { data } = response;
          if (data && data.Status) {
            this.userDetails = data.data;
            this.organization = this.userDetails.organization_data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
          });
        });
    },
    logout: function () {
      UserService.logoutUser().then((res) => {
        TokenService.removeToken();
        ProfileService.removeProfile();
        localStorage.removeItem("UserName");
        localStorage.removeItem("TempToken");
        localStorage.removeItem("UserRole");
        localStorage.removeItem("OrganizationID");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
          });
        }, 1000);
      });
    },
    updateValueAction: function (selectedOption, id) {
      this.selectedOrganization = selectedOption.organization_id;
      localStorage.setItem("OrganizationID", selectedOption.organization_id);
      if (this.selectedOrganization) {
        this.submitStatus = false;
      } else {
        this.submitStatus = true;
      }
      this.getSelectedOrgUserProfile();
    },
    getSelectedOrgUserProfile: function () {
      UserService.getProfileInfo()
        .then((response) => {
          const { data } = response;
          if (data && data.Status) {
            this.userDetails = data.data;
            console.log(this.userDetails, "yyyy");
            this.organization = this.userDetails.organization_data;
            if (
              this.userDetails.user_role &&
              this.userDetails.user_role != "Super Admin" &&
              this.userDetails.user_role != "Admin"
            ) {
              if (this.userDetails.modulePermissions.length > 0) {
                localStorage.setItem(
                  "moduleAccess",
                  this.userDetails.modulePermissions[0].accessModuleId
                );
                localStorage.setItem(
                  "permissionPageAccess",
                  this.userDetails.modulePermissions[0].pgAccessId
                );
                localStorage.setItem(
                  "projectIdsAccess",
                  this.userDetails.modulePermissions[0].project_ids
                );
              } else {
                setTimeout(() => {
                  // this.$router.push({
                  //   path: "/contractView"
                  // });
                  this.$router.push({
                    path: "/pages/not-authorized",
                  });
                }, 500);
              }
            }
            if (this.organization.length == 1) {
              if (this.userDetails.dashboard_user.length > 0) {
                let levelProject = [];
                let userlevelProject = [];
                this.userDetails.dashboard_user.map((x) => {
                  levelProject.push(x.project_id);
                  userlevelProject.push(x.user_level);
                });
                localStorage.setItem("levelProject", levelProject.toString());
                localStorage.setItem(
                  "userlevelProject",
                  userlevelProject.toString()
                );
              }
              if (
                this.userDetails.user_role &&
                this.userDetails.user_role != "Super Admin" &&
                this.userDetails.user_role != "Admin"
              ) {
                if (this.userDetails.modulePermissions.length > 0) {
                  localStorage.setItem(
                    "moduleAccess",
                    this.userDetails.modulePermissions[0].accessModuleId
                  );
                  localStorage.setItem(
                    "permissionPageAccess",
                    this.userDetails.modulePermissions[0].pgAccessId
                  );
                  localStorage.setItem(
                    "projectIdsAccess",
                    this.userDetails.modulePermissions[0].project_ids
                  );
                }
              }
              localStorage.setItem(
                "OrganizationID",
                this.organization[0].organization_id
              );
              UserService.getProfileInfoViaOrg(
                this.organization[0].organization_id
              )
                .then((response) => {
                  const { data } = response;
                  if (data && data.Status) {
                    let profile = data.data;
                    let userRole = data.data.user_role;
                    let dashboardUser = data.data.dashboard_user;
                    let contractProjects = data.data.contractProjects;
                    this.$vs.loading.close();
                    let checkUserType = data.data.checkUserType;
                    // let checkUserType = data.data.checkUserType;
                    localStorage.setItem("UserType", checkUserType);
                    localStorage.setItem(
                      "isAdvanced",
                      data.data.advance_isActive
                    );
                    localStorage.setItem(
                      "isCreditNote",
                      data.data.creditNote_isActive
                    );
                    localStorage.setItem(
                      "isShootLocation",
                      data.data.isShootLocation
                    );
                    localStorage.setItem("isTally", data.data.tally_isActive);
                    localStorage.setItem(
                      "digiSign",
                      data.data.digiSign_isActive
                    );
                    localStorage.setItem("dcr", data.data.dcr_isActive);
                    if (checkUserType == 1) {
                      if (
                        userRole == "AdminManager" ||
                        userRole == "Moderator"
                      ) {
                        userRole = "User";
                      }
                    }
                    let userName = data.data.user_name;
                    localStorage.setItem("UserRole", userRole);
                    localStorage.setItem("UserName", userName);
                    if (checkUserType == 1) {
                      let userName = data.data.user_name;
                      localStorage.setItem("UserRole", userRole);
                      localStorage.setItem("UserName", userName);
                      setTimeout(() => {
                        // this.$router.push({
                        //   path: "/contractView"
                        // });
                        this.$router.push({
                          path: "/",
                        });
                      }, 500);
                      return false;
                    }
                    if (userRole == "User") {
                      if (
                        dashboardUser.length == 0 &&
                        contractProjects.length == 0
                      ) {
                        setTimeout(() => {
                          // this.$router.push({
                          //   path: "/contractView"
                          // });
                          this.$router.push({
                            path: "/",
                          });
                        }, 500);
                        // this.logout();
                      } else {
                        let userName = data.data.user_name;
                        localStorage.setItem("UserRole", userRole);
                        localStorage.setItem("UserName", userName);
                        setTimeout(() => {
                          this.$nextTick(() => {
                            this.$store.dispatch("setPermissions");
                            this.$forceUpdate();
                          });
                        }, 500);
                        setTimeout(() => {
                          this.$router.push({
                            path: "/",
                          });
                        }, 500);
                      }
                    } else if (
                      userRole == "Super Admin" ||
                      userRole == "Admin" ||
                      userRole == "Admin Manager"
                    ) {
                      let userName = data.data.user_name;
                      localStorage.setItem("UserRole", userRole);
                      localStorage.setItem("UserName", userName);
                      setTimeout(() => {
                        this.$nextTick(() => {
                          this.$store.dispatch("setPermissions");
                          this.$forceUpdate();
                        });
                      }, 500);
                      // ProfileService.saveProfile(profile);
                      setTimeout(() => {
                        this.$router.push({
                          path: "/",
                        });
                      }, 500);
                    } else {
                      let userName = data.data.user_name;
                      localStorage.setItem("UserRole", userRole);
                      localStorage.setItem("UserName", userName);
                      setTimeout(() => {
                        this.$nextTick(() => {
                          this.$store.dispatch("setPermissions");
                          this.$forceUpdate();
                        });
                      }, 500);
                      // ProfileService.saveProfile(profile);
                      setTimeout(() => {
                        this.$router.push({
                          path: "/",
                        });
                      }, 500);
                    }
                  }
                })
                .catch((error) => {
                  this.$vs.notify({
                    title: "error",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "warning",
                  });
                });
            } else {
              this.$router.push({
                path: "/pages/landingPage",
              });
            }
            // } else {
            //   this.$vs.notify({
            //     title: "Access Denied",
            //     iconPack: "feather",
            //     icon: "icon-alert-circle",
            //     color: "warning"
            //   });
            // }
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
          });
        });
    },
  },
};
</script>
